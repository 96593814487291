export const getFilters = data => data.map(element => ({
  text: element.value,
  value: element.code
}))
export const getDefaultColumns = (data, isScreening = true) => [
  {
    title: '处理笺名称',
    dataIndex: 'name',
    ellipsis: true
  },
  {
    title: '来文字号',
    dataIndex: 'documentNumber',
    ellipsis: true
  },
  {
    title: '来文单位',
    dataIndex: 'receiverCompany',
    ellipsis: true
  },
  {
    title: '类型',
    dataIndex: 'documentType',
    // width: 150,
    scopedSlots: { customRender: 'documentType' }
  },
  {
    title: '紧急程度',
    dataIndex: 'urgencyDegree',
    // width: 100,
    scopedSlots: { customRender: 'urgencyDegree' }
  },
  {
    title: '密级程度',
    dataIndex: 'secrecyDegree',
    // width: 100,
    scopedSlots: { customRender: 'secrecyDegree' }
  },

  {
    title: '经办人',
    dataIndex: 'draftId',
    // width: 120,
    scopedSlots: { customRender: 'draftId' }
  },

  {
    title: '创建日期',
    dataIndex: 'createTime',
    // width: 150,
    scopedSlots: { customRender: 'acceptTime' }
  },
  {
    title: '状态',
    dataIndex: 'approvalStatus',
    // width: 120,
    scopedSlots: { customRender: 'approvalStatus' },
    filterMultiple: false,
    filters: isScreening && getFilters(data)
  },
  {
    title: '操作',
    dataIndex: 'action',
    // fixed:'right',
    // width: 240,
    scopedSlots: { customRender: 'action' }
  }
]
