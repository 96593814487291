import { getFilters } from '@/views/fileStream/documentManage/config'
export const getDefaultColumns = (data, isScreening = true) => [
  {
    title: '处理笺名称',
    dataIndex: 'name',
    ellipsis: true
  },
  // {
  //   title: '流水号',
  //   dataIndex: 'serialNumber',
  // },
  {
    title: '来文/发文字号',
    dataIndex: 'documentNumber',
    ellipsis: true
  },
  {
    title: '紧急程度',
    dataIndex: 'urgencyDegree',
    // width: 100,
    scopedSlots: { customRender: 'urgencyDegree' }
  },
  {
    title: '密级程度',
    dataIndex: 'secrecyDegree',
    // width: 100,
    scopedSlots: { customRender: 'secrecyDegree' }
  },
  {
    title: '类型',
    dataIndex: 'documentType',
    // width: 150,
    scopedSlots: { customRender: 'documentType' }
  },
  {
    title: '经办/拟稿人',
    dataIndex: 'draftId',
    // width: 120,
    scopedSlots: { customRender: 'draftId' }
  },

  {
    title: '创建日期',
    dataIndex: 'createTime',
    // width: 150,
    scopedSlots: { customRender: 'createTime' }
  },
  // {
  //   title: '收文日期',
  //   dataIndex: 'acceptTime',
  //   scopedSlots: { customRender: 'acceptTime' },
  // },
  {
    title: '状态',
    dataIndex: 'acceptStatus',
    scopedSlots: { customRender: 'acceptStatus' },
    filterMultiple: false,
    // width: 120,
    filters: isScreening && getFilters(data)
  },
  {
    title: '操作',
    dataIndex: 'action',
    // fixed:'right',
    // width: 240,
    scopedSlots: { customRender: 'action' }
  }
]
