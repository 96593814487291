import { sysFileInfoDownload, wxGetPreviewUrl } from '@/api/modular/system/fileManage'
import { DEVICE_TYPE } from '@/utils/device'
import { canPreview, images } from './config'
export const downLoad = {
  methods: {
    download(record) {
      this.cardLoading = true
      this.spinning=true
      sysFileInfoDownload({ id: record.fileId })
        .then((res) => {
          this.cardLoading = false
          this.spinning=false
          this.downloadfile(res)
          // eslint-disable-next-line handle-callback-err
        })
        .catch((err) => {
          this.cardLoading = false
          this.spinning=false
          this.$message.error('下载错误：获取文件流错误')
        })
    },
    preView(record) {
      if (this.$store.state.app.device === DEVICE_TYPE.MOBILE) {

        try {
          window.wx && window.wx.previewFile({
            url: record.fileUrl,
            name: record.fileName,
            size: Number(record.fileSizeKb) * 1024
          })
        } catch (err) {
          alert(JSON.stringify(err.message))
        }
        return
      }
      wxGetPreviewUrl({
        fileObjectName: record.fileObjectName
      })
        .then(res => {
          if (res.code === 200 && res.data) {
            if (canPreview.some(j => res.data.endsWith(j))) {
              this.preViewFile(`${res.data}?ci-process=doc-preview&dstType=html`)
            } else if (images.some(j => res.data.endsWith(j))) {
              this.preViewFile(res.data)
            } else {
              this.$message.waring('暂不支持此类文件的预览')
            }
          }
        })  
    },
    getFileType(filename) {
      return ['.pdf', '.png', '.jpeg', '.gif', '.jpg'].some(j => filename.endsWith(j))
    },
    preViewFile(href) {
      window.open(href, '_blank')
    },
    creatUrl(res) {
      var blob = new Blob([res.data], { type: 'application/octet-stream;charset=UTF-8' })
      var contentDisposition = res.headers['content-disposition']
      var patt = new RegExp('filename=([^;]+\\.[^\\.;]+);*')
      var result = patt.exec(contentDisposition)
      var filename = result[1]
      var href = window.URL.createObjectURL(blob) // 创建下载的链接
      return { filename, href, blob }
    },
    downloadfile(res) {
      var { filename, href } = this.creatUrl(res)
      var downloadElement = document.createElement('a')
      var reg = /^["](.*)["]$/g
      downloadElement.style.display = 'none'
      downloadElement.href = href
      downloadElement.download = decodeURI(filename.replace(reg, '$1')) // 下载后文件名
      document.body.appendChild(downloadElement)
      downloadElement.click() // 点击下载
      document.body.removeChild(downloadElement) // 下载完成移除元素
      window.URL.revokeObjectURL(href)
    },

  }
}
