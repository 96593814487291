
import selectPersonAndDepart, {
  dealResult,
  getUserListMsg
} from '@/views/fileStream/documentManage/weixin/selectPerson'
import { downLoad } from './downLoad'
export const selectPerson = {
  data() {
    return {
      type: null,
      all_list: [],
      // 白名单
      whiteList: ['csr_list', 'mainList']
    }
  },
  methods: {
    // 通讯录弹窗 // person_type 其他（员工）/1部门
    showSelctDialog(key, type, index, all_list) {
      console.log('all_list+++++++', all_list)
      type && (this.type = type)
      all_list && (this.all_list = all_list)
      this.$refs.selectPersonDialog.open({ key, index, all_list })
    },
    objectEquail(obj1, obj2) {
      return JSON.stringify(obj1) === JSON.stringify(obj2)
    },
    selectPersonAndPart(list, index) {
      console.log(this[list], 'list++++++', list)
      const { selectedOpenUserIds, selectedCorpGroupUserIds, selectedDepartmentIds, selectedCorpGroupDepartmentIds } =
        getUserListMsg(list, index, this[list])
      selectPersonAndDepart({
        selectedOpenUserIds,
        selectedCorpGroupDepartmentIds,
        selectedDepartmentIds,
        selectedCorpGroupUserIds,
        type: this.type, // 1 部门 其他 员工
      })
        .then((res) => {
          const { result, resPart } = dealResult(res)
          const resultCopy = [...result, ...resPart]
          let isUserNode = this.confineNodeSelectByChoosePerson && this.confineNodeSelectByChoosePerson(result, this[list])
          if (isUserNode) {
            this[list] = resultCopy.map(item => item)
          }
          // this.$set(this, list, [...result, ...resPart])
          this.updataMessage && this.updataMessage()
        })
        .catch((err) => {
          console.log(err)
        })
    },
    choosePerson(list, index) {
      this.selectPersonAndPart(list, index)
    },
    selectTodoMsg({ type, data: { key, index, result, all_list } }) {
      if (type === 'book') {
        all_list && (this.all_list = all_list)
        this.choosePerson(key, index)
      } else if (type === 'label') {
        let isUserNode = this.confineNodeSelect && this.confineNodeSelect(result)
        if (isUserNode) {
          if (key === 'list') {
            result.forEach((ele) => {
              if (!this[key][index].val.some((j) => this.objectEquail(j, ele))) {
                this[key][index].val.push(ele)
              }
            })
          } else {
            result.forEach((element) => {
              if (!this[key].some((j) => this.objectEquail(j, element))) {
                this[key].push(element)
              }
            })
          }
        }
        this.updataMessage && this.updataMessage()
      }
    },
    confineNodeSelectByChoosePerson(list = [], resultList = []) {
      if (this.allList && this.allList.length < 0) return true
      let ids = resultList.map(item => item.userId || item.id)
      let new_list = list.filter(item => {
        return !ids.includes(item.userId || item.id)
      })
      let allIds = this.all_list.flat(Infinity).map(item => item.userId || item.id)
      let userNode = new_list.find((item) => {
        return allIds.includes(item.userId || item.id)
      })
      if (userNode) {
        this.$message.error('不同的节点不能选取相同的员工')
      }
      return !userNode
    },
    // 不同节点不能选中同一个人
    confineNodeSelect(list = []) {
      if (this.allList && this.allList.length < 0) return true
      let allIds = this.all_list.map(item => item.userId || item.id)
      let userNode = list.find((item) => {
        return allIds.includes(item.userId)
      })
      if (userNode) {
        this.$message.error('不同的节点不能选取相同的员工')
      }
      return !userNode
    },
    // 是否在白名单内
    hasWhiteList(list) {
      return this.whiteList.includes(list)
    }
  }
}

export const stateColor = {
  data() { },
  methods: {
    // 密级程度
    flowUrgencyDegree(state) {
      switch (+state) {
        case 1:
          return {
            color: '#ffcc00'
          }
        case 2:
          return {
            color: '#f88203'
          }
        case 3:
          return {

            color: '#f80320'
          }
        default:
          return {
            color: '#f80320'
          }
      }
    },
    // 密级程度
    flowSecrecyDegree(state) {
      switch (+state) {
        case 1:
          return {
            color: '#33f7b0'

          }
        case 2:
          return {
            color: '#a8c3ff'

          }
        case 3:
          return {
            color: '#ffcc00'
          }
        case 4:
          return {
            color: '#f88203'
          }
        case 5:
          return {
            color: '#f80320'
          }
        default:
          return '#f80320'
      }
    },
  }
}
export const downLoadAndPre = downLoad


// 处理上传文件数据
export const handleUploadCommon = {
  methods: {
    handleRepeatForFileName(file, fileList = []) {
      if (file) {
        const { name } = file
        if (!name) return
        let suffix = name.match(/[^.]+$/)[0] // 文件名后缀
        let fileName = name.slice(0, name.lastIndexOf('.')) // 文件名
        let isFile = fileList.find(item => {
          const { fileName: name } = item
          let _suffix = name.match(/[^.]+$/)[0] // 文件名后缀
          let _fileName = name.slice(0, name.lastIndexOf('.')) // 文件名
          if (_suffix == suffix && _fileName == fileName) {//后缀名相同
            return item
          }
        })
        return isFile
      }
    }
  }
}

// 文件上传
export const uploadCommon = {
  data() {
    return {
      headers: {
        Authorization: "Bearer " + Vue.ls.get('Access-Token'),
      }, // 请求头   
      fileActivUrl: '/api/sysFileInfo/upload', // 上传url
      uploadStatus: null, //  上传状态
      uploadKey: 'ruleList', // 
      formModel: 'form_add',  // 绑定的model
      fileSize: 0, // 限制的文件发小 M
      fileType: [], // 限制的上传文件类型
      maxLength: 0   // 限制的上传的文件数量
    }
  },
  methods: {
    handleChange(info) {
      const {
        file: { response, status }
      } = info
      this.uploadStatus = status
      if (response) {
        const { success, message } = response
        if (!success) {
          // 要处理的事情
          this.handleUploadFile(true, message)
        }
      }
    },
    // 上传文件之前处理
    beforeUpload(file, fileList) {
      return new Promise((resolve, reject) => {
        // let isRepeat = this.handleRepeatForFileName(file, this[this.formModel][this.uploadKey])
        // if (isRepeat) {
        //   reject('上传失败：上传文件相同')
        //   return this.$message.error('上传失败：上传文件相同')
        // } 
        let isMaxLen = +this.maxLength ? this[this.formModel][this.uploadKey].length + fileList.length > this.maxLength : false
        if (isMaxLen) {
          reject(false)
          return this.$message.error(`上传文件支持最多${this.maxLength}个`)
        }
        const { size, name } = file
        let suffix = name.match(/[^.]+$/)[0] // 文件名后缀
        let isUpload = this.fileSize ? size / 1024 / 1024 > this.fileSize : false
        let isSuffix = Array.isArray(this.fileType) && !this.fileType.includes(suffix) || false
        if (isUpload) {
          reject(false)
          return this.$message.error(`上传失败：文件大于${this.fileSize}M`)
        }
        if (isSuffix) {
          reject(false)
          return this.$message.error('上传失败：文件格式不正确')
        }
        if (this.uploadStatus) {
          this[this.formModel][this.uploadKey] = [...this[this.formModel][this.uploadKey], ...fileList]
        } else {
          this[this.formModel][this.uploadKey] = fileList
        }
        resolve(true)
      })
    },
    // 上传失败是文件列表列表
    handleUploadFile(isShowMessage = true, message = '上传失败了') {
      if (isShowMessage) this.$message.error(message)
      this[this.formModel][this.uploadKey] = []
    },
    // 文件上传未完成不能提交文件
    unableSubmitForUploadStatus() {
      if (this.uploadStatus == 'uploading') {
        this.$message.error('文件未上传成功,请耐心等待~~')
        return true
      }
      if (this.uploadStatus == 'error') {
        this.$message.error('文件上传失败,请重新上传~~')
        return true
      }
    }
  }
}

