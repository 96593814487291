<template>
    <div class="documentManagement box" id="ygh-content-box">
        <div class="big_title">
            <h3>公文管理</h3>
            <span>显示所有人登记，发送的公文，方便统一管理所有的收发文情况，已删公文可在回收站恢复或永久删除。</span>
        </div>
        <div class="box-btns">
            <a-button
                :type="btck == i ? 'primary' : ''"
                icon="file-sync"
                class="btns"
                v-for="(o, i) in btna"
                :key="i"
                @click="change_status(i)"
                >{{ o }}</a-button
            >
            <!-- <a-button @click="translateToRe">sss</a-button> -->
        </div>
        <s-table
            ref="table"
            :columns="columns"
            :data="loadData"
            :alert="false"
            :rowKey="(record) => record.id"
            :rowSelection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
        >
            <span slot="operator">
                <div class="flex operatorContainer" style="margin: 10px 0 0">
                    <div>
                        <a-input-search
                            placeholder="请输入关键字"
                            @search="onSearch"
                            class="search"
                            v-model="queryParam.name"
                        >
                            <a-button slot="enterButton" icon="search"> </a-button>
                        </a-input-search>
                    </div>
                    <div v-if="btck === 0">
                        <a-popconfirm
                            v-if="selectedRowKeys && selectedRowKeys.length"
                            placement="topRight"
                            title="确认删除？"
                            @confirm="() => muitDelete()"
                        >
                            <a-button type="danger">批量删除</a-button>
                        </a-popconfirm>
                        <a-button v-else disabled>批量删除</a-button>
                    </div>
                    <div v-else>
                        <a-popconfirm
                            v-if="selectedRowKeys && selectedRowKeys.length"
                            placement="topRight"
                            title="恢复将取消公文删除状态，确定要恢复吗？"
                            @confirm="() => muitRestore()"
                        >
                            <a-button style="margin-right: 17px" type="primary">批量恢复</a-button>
                        </a-popconfirm>
                        <a-button v-else style="margin-right: 17px" type="primary" disabled>批量恢复</a-button>
                        <a-popconfirm
                            v-if="selectedRowKeys && selectedRowKeys.length"
                            placement="topRight"
                            title="彻底删除公文将不可恢复，确定要删除吗？"
                            @confirm="() => muitWipeOut()"
                        >
                            <a-button type="danger">批量彻底删除</a-button>
                        </a-popconfirm>
                        <a-button v-else type="danger" disabled>批量彻底删除</a-button>
                    </div>
                </div>
            </span>
            <span slot="draftId" slot-scope="text, record">
                <div v-for="(o, i) in record.draftId" :key="i" class="my-item">
                    <a-tag class="tags flex0" color="blue">
                        <OpenData
                            :type="o.type || 'userName'"
                            :openid="o.departmentId || o.userId"
                            :corpid="o.corpId"
                        ></OpenData>
                    </a-tag>
                </div>
                <!-- <OpenData type="userName" :openid="record.draftId" :corpid="record.corpId"></OpenData> -->
            </span>
            <span slot="documentType" slot-scope="text">
                <span v-if="text == +1">收文登记</span>
                <span v-else-if="text == +2">快速发文</span>
                <span v-else-if="text == +3">拟稿发文</span>
            </span>
            <span slot="createTime" slot-scope="text">
                {{ text && text.substr(0, 10) }}
            </span>
            <span slot="acceptTime" slot-scope="text">
                {{ text && text.substr(0, 10) }}
            </span>
            <span slot="urgencyDegree" slot-scope="text" :style="flowUrgencyDegree(text)">
                {{ Filter(flow_urgency_degree, text) }}
            </span>
            <span slot="secrecyDegree" slot-scope="text" :style="flowSecrecyDegree(text)">
                {{ Filter(flow_secrecy_degree, text) }}
            </span>

            <span slot="acceptStatus" slot-scope="text, record" :class="text != 3 ? 'fred' : ''">
                {{ Filter(flow_approval_status, record.approvalStatus) }}
            </span>
            <span slot="action" slot-scope="text, record">
                <a-button style="padding-left: 0" type="link" @click="see(record)">查看</a-button>
                <a-popconfirm
                    v-if="btck === 0"
                    placement="topRight"
                    title="确认删除？"
                    @confirm="() => singleDelete(record)"
                >
                    <a-button style="color: red; padding-left: 0" type="link">删除</a-button>
                </a-popconfirm>
                <template v-else>
                    <a-popconfirm
                        placement="topRight"
                        title="恢复将取消公文删除状态，确定要恢复吗？"
                        @confirm="() => restore(record)"
                    >
                        <a-button style="padding-left: 0" type="link">恢复</a-button>
                    </a-popconfirm>
                    <a-popconfirm
                        placement="topRight"
                        title="彻底删除公文将不可恢复，确定要删除吗？"
                        @confirm="() => wipeOut(record)"
                    >
                        <a-button style="color: red; padding-left: 0" type="link">彻底删除</a-button>
                    </a-popconfirm>
                </template>
            </span>
        </s-table>
    </div>
</template>
<script>
import OpenData from '@/views/fileStream/documentManage/weixin/openData'
// eslint-disable-next-line camelcase
import {
    delDocumentBill,
    documentBillAllPage,
    documentBillCountermand,
    documentBillExpurgate,
} from '@/api/modular/fileStream/documentManage'
import { sysDictTypeDropDown } from '@/api/modular/system/dictManage'
import { STable } from '@/components'
import { stateColor } from '@/mixins/index.js'
import { handleAppointEleHeight } from '@/utils/common.js'
import { init_wx } from '@/views/fileStream/documentManage/weixin/weixin'
import { getDefaultColumns } from './config'
export default {
    components: {
        OpenData,
        STable,
    },
    mixins: [stateColor],
    data() {
        const formatDraftId = (id) => {
            if (!id) return []
            return JSON.parse(id)
        }
        return {
            btna: ['全部', '回收站'],
            btck: 0,
            selectedRowKeys: [],
            queryParam: {
                approvalTab: 1,
            },
            flow_secrecy_degree: [],
            flow_urgency_degree: [],
            flow_file_type: [],
            flow_approval_status: [],
            columns: [],
            func: documentBillAllPage,
            loadData: (parameter) => {
                const { acceptStatus } = parameter
                let params = { ...parameter }
                if (acceptStatus && acceptStatus.length > 0) params = { ...parameter, approvalStatus: acceptStatus[0] }
                delete params.acceptStatus
                return this.func(Object.assign(params, this.queryParam)).then((res) => {
                    const data = { ...res.data }
                    data.rows = data.rows.map((ele) => {
                        ele.draftId = formatDraftId(ele.draftId)
                        return ele
                    })
                    handleAppointEleHeight('#ygh-content-box', ['.box-btns', '.big_title', '.s-table-tool'], 66, res)
                    return data
                })
            },
        }
    },
    created() {
        init_wx()
        this.getDict()
    },
    mounted() {
        handleAppointEleHeight('#ygh-content-box', ['.box-btns', '.big_title', '.s-table-tool'])
    },
    methods: {
        restore({ id }) {
            documentBillCountermand([{ id }]).then((res) => {
                if (res.code === 200) {
                    this.$message.success('恢复成功')
                    this.$refs.table.refresh()
                }
            })
        },
        muitRestore() {
            documentBillCountermand(this.selectedRowKeys.map((j) => ({ id: j }))).then((res) => {
                if (res.code === 200) {
                    this.$message.success('批量恢复成功')
                    this.selectedRowKeys = []
                    this.$refs.table.refresh()
                }
            })
        },
        wipeOut({ id }) {
            documentBillExpurgate([{ id }]).then((res) => {
                if (res.code === 200) {
                    this.$message.success('彻底删除成功')
                    this.$refs.table.refresh()
                }
            })
        },
        muitWipeOut() {
            documentBillExpurgate(this.selectedRowKeys.map((j) => ({ id: j }))).then((res) => {
                if (res.code === 200) {
                    this.$message.success('批量彻底删除成功')
                    this.selectedRowKeys = []
                    this.$refs.table.refresh()
                }
            })
        },
        muitDelete() {
            delDocumentBill(this.selectedRowKeys.map((j) => ({ id: j }))).then((res) => {
                if (res.code === 200) {
                    this.$message.success('批量删除成功')
                    this.selectedRowKeys = []
                    this.$refs.table.refresh()
                }
            })
        },
        singleDelete({ id }) {
            delDocumentBill([
                {
                    id,
                },
            ]).then((res) => {
                if (res.code === 200) {
                    this.$message.success('删除成功')
                    this.$refs.table.refresh()
                }
            })
        },
        onSelectChange(selectedRowKeys) {
            this.selectedRowKeys = selectedRowKeys
        },
        foramtAcceptStatus(text) {
            switch (Number(text)) {
                case 0:
                    return '待接收'
                case 1:
                    return '已接收'
                default:
                    return ''
            }
        },
        change_status(i) {
            this.btck = i
            this.queryParam.approvalTab = i + 1
            this.columns = getDefaultColumns(this.flow_approval_status, i === 0)
            this.$refs.table.refresh()
        },
        onSearch(value) {
            this.$refs.table.refresh(true)
        },
        /**
         * 获取字典数据
         */
        getDict() {
            sysDictTypeDropDown({ code: 'flow_secrecy_degree' }).then((res) => {
                this.flow_secrecy_degree = res.data
            })
            sysDictTypeDropDown({ code: 'flow_urgency_degree' }).then((res) => {
                this.flow_urgency_degree = res.data
            })
            sysDictTypeDropDown({ code: 'flow_file_type' }).then((res) => {
                this.flow_file_type = res.data
            })
            sysDictTypeDropDown({ code: 'flow_approval_status' }).then((res) => {
                console.log('res.data+++++++++', res.data)
                this.flow_approval_status = res.data
                this.columns = getDefaultColumns(res.data)
            })
        },
        edit(record) {
            this.$router.push({ path: '/fileStreamDocumentManage', query: { id: record.id } })
        },
        senpi(record) {
            const { approvalId, nodeId, id } = record
            this.$router.push({ path: '/fileStream_Approval_Manage', query: { id, approvalId, nodeId } })
        },
        see(record) {
            let url = '/fileStream_Approval_process'
            // eslint-disable-next-line eqeqeq
            if (record.documentType == 2) {
                url = '/fileStreamDocumentSend'
            }
            console.log('url++++++++++++', url)
            this.$router.push({ path: url, query: { id: record.id, type: record.documentType } })
        },
        Filter(arr, s) {
            const values = arr.filter((item) => Number(item.code) === Number(s))
            if (values.length > 0) {
                return values[0].value
            }
        },
    },
}
</script>
<style lang="less" scoped>
.big_title {
    margin: 5px 0 20px;
    h3 {
        color: #000000;
        font-size: 20px;
        font-family: Microsoft YaHei;
        font-weight: 400;
    }
    span {
        color: #9d9d9d;
        font-size: 14px;
    }
}
.btns {
    margin-right: 17px;
}
.box {
    background: #ffffff;
    border-radius: 4px;
    padding: 33px 39px;
}
.documentManagement {
    margin: auto;
    // overflow-y: auto;
}
.operatorContainer {
    justify-content: space-between;
}
</style>
